import { Link } from "react-router-dom";
import EmailSubscribe from "./EmailSubscribe";
import { nav } from "../../Data";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ReactLenis } from "@studio-freight/react-lenis";
import "./style.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-7 col-lg-6 left">
            <p>
              We respectfully acknowledge that we live, work, and learn on the
              unceded, traditional, and ancestral land of the xʷməθkwəy̓əm
              (Musqueam), Skwxwú7mesh (Squamish), and Səl̓ílwətaʔ/Selilwitulh
              (Tsleil-Waututh) Nations.
            </p>
          </div>
          <div className="col-12 col-md-7 col-lg-3 right">
            <EmailSubscribe />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 left">
            <Link to="/">
              <img
                className="logo"
                src="../images/logo/constec_Logo_White.png"
                alt="Shannon-Lynn_Logo"
              />
            </Link>
          </div>
          <div className="col-12 col-lg-6 right">
            <div className="row social-wrapper">
              <div className="col-12 col-lg-6 left">
                <Link to="mailto: Joshua@constec.ca" className="email">
                  Joshua@constec.ca
                </Link>
                <Link to="/contact" className="">
                  Enquiry Form
                </Link>
              </div>
              <div className="col-12 col-lg-6 right">
                <div className="socials">
                  <Link
                    to="https://www.instagram.com/constecdesigns/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="instagram"
                  >
                    Instagram
                  </Link>
                  <Link
                    to="https://www.facebook.com/constecdesign/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="facebook"
                  >
                    Facebook
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row copyright-row">
          <div className="col-12 col-lg-6 left">
            <Link to="/privacy-legal">Privacy & Legal</Link>
            <div className="copyright">
              © 2024 Constec Design & Contracting Ltd. All rights reserved.
            </div>
          </div>
          <div className="col-12 col-lg-6 right">
            <Link
              to="https://www.alabcreative.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Website by <span className="is-bold">ALab Creative Inc</span>.
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
